(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/view-header/assets/javascripts/receipt.js') >= 0) return;  svs.modules.push('/components/marketplace/view-header/assets/javascripts/receipt.js');

'use strict';

const ReceiptColumn = _ref => {
  let {
    children,
    className
  } = _ref;
  return React.createElement("div", {
    className: className || 'view-header-receipt-column'
  }, children);
};
const ReceiptRow = _ref2 => {
  let {
    children
  } = _ref2;
  return React.createElement("div", {
    className: "padding-bottom view-header-receipt-row padding-right-1 padding-left-1"
  }, children);
};
const ReceiptWrapper = _ref3 => {
  let {
    children
  } = _ref3;
  return React.createElement("div", {
    className: "view-header-receipt"
  }, children);
};
const Receipt = _ref4 => {
  let {
    receiptFields = []
  } = _ref4;
  return React.createElement(ReceiptWrapper, null, receiptFields.map((rows, i) => React.createElement(ReceiptRow, {
    key: "receipt_row_".concat(i * 10)
  }, rows.map((column, j) => React.createElement(ReceiptColumn, {
    className: column.options && column.options.className,
    key: "receipt_column_".concat(j * 10)
  }, column.title && React.createElement("span", {
    className: "fc-grey-500 f-200 f-uppercase block"
  }, column.title), React.createElement("span", {
    className: column.options && column.options.textClassName || 'f-medium'
  }, column.text || '-'))))));
};
setGlobal('svs.components.marketplace.viewHeader.Receipt', Receipt);

 })(window);